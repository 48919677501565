import { applyMiddleware, compose, createStore, Middleware } from "redux";
import { createLogger } from "redux-logger";
import ReduxThunk from "redux-thunk";
import * as config from "../config";
import { initPersist } from "./persist";
import rootReducer from "./rootReducer";

const middlewares: Middleware[] = [ReduxThunk];

if (config.ENABLE_LOGGING) {
  middlewares.push(createLogger({}));
}

const store = createStore(
  rootReducer,
  undefined,
  compose(applyMiddleware(...middlewares))
);

initPersist(store);

export default store;
