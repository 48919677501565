import { RESTORE_STATE, PERSIST_STATE, persistState } from "./persist";

interface LoginStore {
  isLoggedIn: boolean;
}

const initialState: LoginStore = {
  isLoggedIn: false,
};

const resetOnLoad: Partial<LoginStore> = {};

const login = (state = initialState, action: any): LoginStore => {
  switch (action.type) {
    case RESTORE_STATE:
      return {
        ...state,
        ...action.restore.login,
        ...resetOnLoad,
      };

    case PERSIST_STATE:
      persistState({
        login: {
          ...state,
        },
      });
      return state;

    default:
      return state;
  }
};

export default login;
