import { useSelector } from "react-redux";
import login from "./loginReducer";
import { combineReducers } from "redux";

const reducers = {
  login,
};

const rootReducer = combineReducers(reducers);

type ReducersType = typeof reducers;

export type CombinedStore = {
  [P in keyof ReducersType]: ReturnType<ReducersType[P]>;
};

export default rootReducer;

export function useCombinedStore<T>(selector: (store: CombinedStore) => T): T {
  return useSelector<CombinedStore, T>(selector);
}
