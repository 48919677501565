import * as config from "../config";
import { Store } from "redux";

export const RESTORE_STATE = `RESTORE_STATE`;
export const PERSIST_STATE = `PERSIST_STATE`;

const persistedState: any = {};

let store: Store | undefined;

let persistingStore = false;
let persistStoreRequested = false;

let dispatchQueue: any[] = [];

function loadPersistedState() {
  if (
    typeof window === "undefined" ||
    typeof window.localStorage === "undefined"
  ) {
    return {};
  }

  try {
    const jsonState = localStorage.getItem(config.STATE_KEY);
    if (jsonState === null) {
      return {};
    }

    const parsed = JSON.parse(jsonState);

    return typeof parsed === "object" ? parsed : {};
  } catch (error) {
    console.log(`Error restoring state: ${error}`);
    return {};
  }
}

function storePersistedState() {
  if (
    typeof window === "undefined" ||
    typeof window.localStorage === "undefined"
  ) {
    return;
  }

  try {
    const jsonState = JSON.stringify(persistedState);
    localStorage.setItem(config.STATE_KEY, jsonState);
  } catch (error) {
    console.log(`Error preserving state: ${error}`);
  }
}

function persistStoreIfRequested() {
  if (persistingStore || !store || !persistStoreRequested) {
    return;
  }

  persistingStore = true;
  persistStoreRequested = false;

  (store.dispatch as any)(async (dispatch: (action: any) => void) => {
    dispatch({ type: PERSIST_STATE });

    storePersistedState();
    persistingStore = false;
  });
}

export function persistState(incoming: any) {
  Object.assign(persistedState, incoming);
}

export function requestPersist() {
  persistStoreRequested = true;
}

export function queueDispatch(action: any) {
  dispatchQueue.push(action);
}

export function initPersist(aStore: Store) {
  store = aStore;
  store.dispatch({ type: RESTORE_STATE, restore: loadPersistedState() });
  store.subscribe(() => {
    persistStoreIfRequested();
    const queued = dispatchQueue;
    dispatchQueue = [];
    for (const queue of queued) {
      aStore.dispatch(queue);
    }
  });

  if (typeof window !== "undefined") {
    window.addEventListener("storage", () => {
      if (!persistingStore) {
        aStore.dispatch({ type: RESTORE_STATE, restore: loadPersistedState() });
      }
    });
  }
}
